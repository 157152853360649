<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-text-field type="number" :value="editedItem.loyer_cable"
                        @input="update_ei({'field': 'loyer_cable', 'value': $event})"
                        label="Loyer câble"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <BaseUnitSelect
              :value="editedItem.unite_loyer_cable"
              label="Unités"
              @input="(data) => {update_ei({'field': 'unite_loyer_cable', 'value': data})}"
          ></BaseUnitSelect>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field type="number" :value="editedItem.loyer_acces"
                        @input="update_ei({'field': 'loyer_acces', 'value': $event})"
                        label="Loyer voie d'accès"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <BaseUnitSelect
              :value="editedItem.unite_loyer_acces"
              label="Unités"
              @input="(data) => {update_ei({'field': 'unite_loyer_acces', 'value': data})}"
          ></BaseUnitSelect>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {format_functions} from "../../../mixins/UtilityMixin";
import {mapActions, mapState} from "vuex";

export default {
  name: "ConventionDeServitude",
  mixins: [format_functions],
  props: ['refs'],
  data() {
    return {
      defaultItem: {"loyer_cable": 1, 'unite_loyer_cable': "€/ml", 'loyer_acces': 5, 'unite_loyer_acces': '€/m²'},
    }
  },
  created() {
    if (Object.keys(this.editedItem).length === 0) {
      this.init_ei(this.defaultItem)
    }
  },
  methods: {
    ...mapActions('edition', [
      'init_ei',
      'update_ei'
    ]),
  },
  computed: {
    ...mapState({
      editedItem: state => state.edition.editedItem
    }),
  },
}
</script>

<style scoped>

</style>