<template>
  <div>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Loyer Cable: {{ c.loyer_cable }} {{ c.unite_loyer_cable }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Loyer Accès: {{ c.loyer_acces }} {{ c.unite_loyer_acces }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {format_functions} from "../../../mixins/UtilityMixin";

export default {
  name: "ConventionDeServitude",
  mixins: [format_functions],
  props: ['c'],
}
</script>

<style scoped>

</style>